const fetch = require("node-fetch")

class ApiPublic {
  constructor(apiBaseUrl, tenancyId) {
    this.apiBaseUrl = apiBaseUrl
    this.tenancyId = tenancyId
  }

  async get(url){
    return await fetch(url);
  }

  async search(search) {
    const url = this.apiBaseUrl+search.url();
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'tenantid': this.tenancyId
      }});
    return await res.json()
  }
}

class Search {

  constructor(search){
    this.width = search.width;
    this.height = search.height;
    this.diameter = search.diameter;
    this.pageSize = 100;
    this.page = search.page;
    for (let facet of search.facets) {
      if(facet.name === "facetSeason"){
        this.season(facet.value);
      }
      if(facet.name === "facetCarType"){
        this.carType(facet.value);
      }
    }
  }

  season(season){
    this.seasonParam = season;
  }
  carType(carType){
    this.carTypeParam = carType;
  }

  url(){
    let url = `/api/Search/search?height=${this.height}&width=${this.width}&diameter=${this.diameter}&PageSize=${this.pageSize}&PageNumber=${this.page}`;
    if(this.seasonParam){
      url += "&Season="+this.seasonParam;
    }
    if(this.carTypeParam){
      url += "&CarType="+this.carTypeParam;
    }

    return url;
  }

  hasFacetValue(name, value) {
    if(name === "facetSeason"){
      return this.seasonParam === value;
    }

    if(name === "facetCarType"){
      return this.carTypeParam === value;
    }
  }
}

module.exports.ApiPublic = ApiPublic
module.exports.Search = Search