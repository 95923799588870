import React, { useState, useEffect, useMemo } from "react"
import { Box, Card as CardComponent } from 'theme-ui'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import { Layout, Stack, Main, Sidebar } from '@layout'
import * as queryString from "query-string";
import CardList from '@components/CardList'
import translate from '@src/translate'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
} from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { statAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
const { ApiPublic, Search } = require(`./api-client-public.js`)
const { SearchResult } = require(`./SearchResult.js`)

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(statAnatomy.keys)

defineMultiStyleConfig({
  sizes: {
    xs: definePartsStyle({
      label: { fontSize: "xs" },
      helpText: { fontSize: "xs" },
      number: { fontSize: "xs" },
    }),
  }
})

export default function TyreSearchTemplate({
                                      location,
                                       data: page,
                                       ...props
                                     }) {
  const images = useStaticQuery(graphql`
    query TyrePatternImages {
      allTyrePattern {
        edges {
          node {
             brand
             pattern
             image {
              childImageSharp {
                __typename
                ... on ImageSharp {
                  ImageSharp_small: gatsbyImageData(
                    width: 48
                    height: 48
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 75
                  )
                  ImageSharp_regular: gatsbyImageData(
                    width: 130
                    height: 130
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 80
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const api = useMemo(() => new ApiPublic(`${process.env.GATSBY_API_URL}`, `${process.env.GATSBY_TENANCYID}`), []);
  const { width, height, diameter } = queryString.parse(location.search);

  const [error, setError] = useState(null);
  const [search, setSearch] = useState({width, height, diameter, page: 1, facets: []});
  const [searchResult, setSearchResult] = useState([])


  const size = `${width}/${height}R${diameter}`;

  useEffect(() => {
    console.log("Searching for tires")

    if( ! new SearchResult(searchResult).hasNext()) return;

    api.search(new Search(search))
      .then(resultData => {
        setSearchResult(searchResult => [...searchResult, resultData])
      },
        (error) => {
          setError(error);
        }
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, api])

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight

    if (scrollTop + clientHeight >= scrollHeight) {
      setSearch({ ...search, ...{page: search.page + 1} });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult])

  let transformed = [];
    new SearchResult(searchResult).items().forEach(tire => {
    let excerpt = (
      <div key={ "excerpt-"+tire.ean }>
        <div>{ `${tire.size.width}/${tire.size.height}R${tire.size.diameter} ${tire.loadIndex} ${tire.speedIndex}` }</div>
        <Divider/>
        <StatGroup>
          <Stat size="xs" >
            <StatLabel>Biltype</StatLabel>
            <StatNumber>{translate(tire.carType).toLowerCase()}</StatNumber>
          </Stat>

          <Stat size="xs" >
            <StatLabel>Sæson</StatLabel>
            <StatNumber>{translate(tire.season).toLowerCase()}</StatNumber>
          </Stat>

          <Stat size="xs" >
            <StatLabel>EAN</StatLabel>
            <StatNumber>{tire.ean}</StatNumber>
          </Stat>
        </StatGroup>
      </div>
    );
    let image = findImage(images, tire.brand, tire.pattern);
    transformed.push(
      {
        id: tire.ean,
        slug: `/dæk/${tire.brand.toLowerCase()}/${tire.pattern.toLowerCase()}/${tire.size.width}/${tire.size.height}R${tire.size.diameter} ${tire.loadIndex} ${tire.speedIndex}`,
        title: `${tire.brand} ${tire.pattern}`,
        thumbnail: image == null ? null : image.childImageSharp,
        imageVariant: "regular",
        excerpt,
        featured: true,
      }
    );
  });

  let renderResults = function(){
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if ( ! new SearchResult(searchResult).isLoaded()) {
      return <div>Henter dæk...</div>;
    } else {
      return <div>
        Vi fandt {new SearchResult(searchResult).count()} dæk i størrelsen {size}.

      <CardList
        title={""}
        nodes={transformed}
        variant={['tyrepattern']} />
      </div>
    }
  };

  let facetChecked = function(name, value){
    return new Search(search).hasFacetValue(name, value);
  }

  let facetChanged = function(e){
    setSearchResult([])

    let isChecked = e.target.checked;
    let [facet, value] = e.target.value.split("=");
    if(isChecked) {
      setSearch({ ...search, ...{ page: 1, facets: [...search.facets, { name: facet, value: value }] } });
    }else{
      setSearch({ ...search, ...{ page: 1, facets: search.facets.filter(item => item.name !== facet) } });
    }
  }

  return (
    <Layout {...props}>
      <Seo {...props} description="" title={size+" dæk"} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={size+ " dæk" }  />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            { renderResults() }
          </CardComponent>
        </Main>
        <Sidebar>
          <Accordion allowMultiple defaultIndex={[0,1]}>
              {
                Object.keys(new SearchResult(searchResult).facets()).map( (key) =>
                  <AccordionItem key={key}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          { translate(key) }
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <SimpleGrid columns={2} spacing={10}>
                      {
                      new SearchResult(searchResult).facets()[key].buckets.map(e =>
                        <React.Fragment key={key+"="+e.id}>
                          <Box>
                            <input
                              type="checkbox"
                              name="search-form"
                              id="search-form"
                              className="search-input"
                              value={key+"="+e.id}
                              onChange={facetChanged}
                              checked={facetChecked(key, e.id)}
                            />&#160;{ translate(e.id).toLowerCase() }
                          </Box>
                          <Box>({e.count})</Box>
                        </React.Fragment>
                      )
                      }
                      </SimpleGrid>
                    </AccordionPanel>
                  </AccordionItem>
                )
              }
          </Accordion>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

function findImage(images, brand, pattern) {
  let image = images.allTyrePattern.edges.find(x => x.node.brand.toLowerCase() === brand.toLowerCase() && x.node.pattern.toLowerCase() === pattern.toLowerCase());

  if(!image) return null;
  if(!image.node.image) return null;

  return image.node.image;
}
