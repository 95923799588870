class SearchResult {
  constructor(results) {
    this.results = results
  }

  items(){
    if(this.results.length === 0) return [];
    let combined = [];
    this.results.forEach( results => {
      combined = combined.concat(results.results);
    })
    return combined;
  }
  hasNext(){
    if(this.results.length === 0) return true;
    return this.results.at(-1).meta.hasNext;
  }

  isLoaded(){
    return this.results.length !== 0;
  }

  facets(){
    if(this.results.length === 0) return 0;
    return this.results.at(-1).meta.facet;
  }

  count() {
    if(this.results.length === 0) return 0;

    return this.results.at(-1).meta.count;
  }
}

module.exports.SearchResult = SearchResult
